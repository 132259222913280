<template>
  <div class="product" v-if="items">
    <div class="product-img">
      <img :src="items.cover" alt="" style="object-fit: cover;" />
    </div>
    <div class="product-desc">
      <div class="title" style="font-weight: bold; font-size: 18px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis">
        {{ items.title }}
      </div>
      <div class="flex">
        <span style="color:#878E99">商城价</span>
        <span style="color:red;margin-left:2px">¥{{items.price}}</span>
        <span style="color:#878E99;margin-left:3px;margin-right:3px">|</span>
        <span style="color:#878E99;margin-left:2px">官方价¥{{items.sell_reference_price}}</span>
      </div>
      <div class="product-price">
        <div class="flex-colume">
          <div class="des">在售最低</div>
          <div class="price">{{ sale_min_price|| '-' }}</div>
        </div>
        <div style="color: #dddddd">｜</div>
        <div class="flex-colume">
          <div class="des">求购最高</div>
          <div class="price">{{ entrust_max_price || '-'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop,Watch } from "vue-property-decorator";
@Component({})
export default class Product extends Vue {
  @Prop() items
  entrust_max_price = ''
  sale_min_price = ''
  @Watch('items')
  watchItems(){
    this.entrust_max_price = (this.items.entrust_max_price == "0.00" ? '-':`¥${this.items.entrust_max_price}`)
    this.sale_min_price = this.items.sale_min_price == '0.00'? '-':`¥${this.items.sale_min_price}`
  }
  async created() {
  }
}
</script>

<style lang="scss" scoped>
.title{
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.product {
  .product-img {
    width: 100%;
    height: 211px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .product-desc {
    background: white;
    height: 155px;
    padding: 20px 16px 0px 16px;
  }
  .product-price {
    height: 73px;
    background: #f8f8f8;
    border-radius: 4px;
    margin: 0px auto;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 62px;
    box-sizing: border-box;
    .flex-colume {
      display: flex;
      align-items: center;
      flex-direction: column;
      .des {
        font-size: 12px;
        color: #878e99;
      }
      .price {
        color: #f83737;
        font-size: 16px;
        font-weight: bold;
        margin-top: 4px;
      }
    }
  }
}
.flex{
  display: flex;
  align-items: center;
  font-size: 12px;
}
</style>
