<template>
  <div class="wrap" v-if="items">
    <Product :items="items" v-if="items" />
    <div style="background: white">
      <div class="intro" style="margin-top: 10px">
        <Tabs v-model="active" line-width="20px" color="black" sticky>
          <Tab title="基本介绍">
            <div
              style="background: white; padding-bottom: 40px"
              v-html="items.detail"
            ></div>
          </Tab>
          <Tab title="当前求购">
            <CurrentInfo
              v-if="playerList.length > 0"
              :playerList="playerList"
            />
            <div v-else style="height: 600px; position: relative">
              <EmptyBox style="z-index: 2" tip="暂无玩家求购" />
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>

    <div style="height: 90px; width: 100%"></div>
    <div class="bottom-flex button">
      <div class="promise">玩家交易&nbsp;&nbsp;&nbsp;平台担保</div>
      <template v-if="!isLogin">
        <div style="display: flex; width: 100%; align-items: center">
          <div class="__flex_column" @click="tohome" v-if="!IsAppState">
            <img
              class="home"
              style="flex-shrink: 0"
              src="https://cdn.vgn.cn/static/Official-website/home.png"
              alt=""
            />
            <div class="title">首页</div>
          </div>
          <div
            class="__btn __btn_dazzling"
            style="margin: 0 auto; width: 80%"
            @click="userLogin"
          >
            立即登录
          </div>
        </div>
      </template>
      <template v-else>
        <div class="__flex_column" @click="tohome" v-if="!IsAppState">
          <img
            class="home"
            style="flex-shrink: 0"
            src="https://cdn.vgn.cn/static/Official-website/home.png"
            alt=""
          />
          <div class="title">首页</div>
        </div>
        <div class="request-buy" :style="{ width: !IsAppState ? '' : '49%' }">
          <wechat-subscribe @click="toMarketPay"> 我要求购 </wechat-subscribe>
        </div>
        <div class="buy" :style="{ width: !IsAppState ? '' : '49%' }">
          <wechat-subscribe @click="rightnowPay">
            <div class="buy-now" style="text-align: center">立即购买</div>
            <div class="low-price">
              最低价{{
                items.sale_min_price == 0 ? "-" : `¥${items.sale_min_price}`
              }}
            </div>
          </wechat-subscribe>
        </div>
      </template>
    </div>
    <wechat-subscribe />
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import Product from "@/components/Product.vue";
import SearchApi from "@/api/bazaar.api";

import { Tab, Tabs } from "vant";
import CurrentInfo from "@/views/market/components/CurrentInfo.vue";
import EmptyBox from "@/components/EmptyBox.vue";
import WechatSubscribe from "@/components/WechatSubscribe.vue";
import GlobalStateStore from "@/store/modules/global-state.store";
import { WechatApi } from "@/api/wechat.api";
import { getModule } from "vuex-module-decorators";
import InteractionLib from "@/utils/interaction.lib";
@Component({
  components: {
    Product,
    Tab,
    Tabs,
    CurrentInfo,
    EmptyBox,
    WechatSubscribe,
  },
})
export default class Market extends Vue {
  active = 0;
  list = {};
  items = {};
  playerList = [];
  subscribe = false;
  globalState = getModule(GlobalStateStore);
  async created() {
    this.globalState.setSce(1);
    this.items = await SearchApi.getProductDetail(this.$route.query.id);
    this.playerList = await SearchApi.getPlayerList(this.$route.query.id);
  }
  get isLogin() {
    return Boolean(this.globalState.userToken);
  }
  async userLogin() {
    location.href = await WechatApi.getAuthUri();
  }
  toMarketPay() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${location.origin}/marketpay/${this.$route.query.id}?right=false`
      );
    }
    this.$router.push({
      name: "marketpay",
      params: {
        id: this.$route.query.id,
      },
    });
  }
  rightnowPay() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${location.origin}/marketpay/${
          this.$route.query.id
        }?right=true`
      );
    }
    this.$router.push({
      name: "marketpay",
      params: {
        id: this.$route.query.id,
      },
      query:{
        right: true,
      }
    });
  }
  tohome() {
    this.$router.push({ path: "/" });
  }
  get IsAppState() {
    return InteractionLib.isApp();
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  position: relative;
  .button {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 12px 20px;
    box-sizing: border-box;
    height: 80px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.intro {
  ::v-deep {
    .van-sticky--fixed {
      background: white;
    }
    .van-tabs__wrap {
      width: 200px;
      .van-tabs__nav {
        .van-tab--active {
          font-weight: 800;
        }
        .van-tabs__line {
          bottom: 19px !important;
        }
      }
    }
  }
}
.desc {
  height: 128px;
  background: #f8f8f8;
  font-size: 13px;
  padding: 0 20px;
  font-weight: 400;
  color: #969696;
}

.bottom-flex {
  position: relative;
  display: flex;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  background: white;
  .promise {
    position: absolute;
    background: linear-gradient(90deg, #ff8500 0%, #ff0000 100%);
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    top: -40px;
    left: 0;
    color: white;
    font-weight: 800;
    letter-spacing: 12px;
  }
  .request-buy {
    width: 43%;
    height: 43px;
    background: #212832;
    border-radius: 24px;
    display: flex;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    color: white;
  }
  .buy {
    width: 43%;
    height: 43px;
    background: linear-gradient(270deg, #ff2626 0%, #ff6e4e 100%);
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    .buy-now {
      font-size: 15px;
      font-weight: bold;
    }
    .low-price {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
.__flex_column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 10%;
  height: 38px;
  .home {
    width: 20px;
    height: 20px;
  }
  .title {
    font-size: 12px;
    color: #121212;
  }
}
</style>
